<template>
    <div class="branch-list">
        <div class="el-content mb12">
            <a-space>
                <a-button class="mb12" type="primary" @click="editSchemeShow">
                    <i class="ri-add-line ri-top"></i>新增方案
                </a-button>
                <a-button class="mb12" type="primary" @click="showEditBranch(0)">
                    <i class="ri-add-line ri-top"></i>新增分支
                </a-button>
            </a-space>
        </div>
        <div class="el-content">
            <div class="kd-pacel">列表</div>
            <a-table :pagination="false" row-key="id" :data-source="scheme"
                :columns="[
                    { title: 'ID', dataIndex: 'id' },
                    { title: '方案名称', dataIndex: 'father_name' },
                    { title: '简单描述', dataIndex: 'description' },
                    { title: '标签说明', dataIndex: 'remark' },
                    { title: '创建时间', dataIndex: 'create_time' },
                    { title: '操作', dataIndex: 'action', slots: { customRender: 'action' } }
                ]"
            >
                <template #action="{record}">
                    <a-space>
                        <kd-button
                            type="success"
                            icon="ri-git-branch-line"
                            title="分支管理"
                            @click="showBranch(record.id)">
                        </kd-button>
                        <kd-button
                            type="primary"
                            icon="ri-edit-line"
                            title="编辑"
                            @click="editSchemeShow(record)"
                            v-has="{ action: 'sass_server_add' }">
                        </kd-button>
                        <kd-button
                            type="danger"
                            icon="ri-delete-bin-line"
                            title="删除"
                            @click="deleteSchemen(record.id)"
                            v-has="{ action: 'sass_server_del' }">
                        </kd-button>
                    </a-space>
                </template>
            </a-table>
        </div>

        <a-modal v-model:visible="schemeState.show" title="添加/编辑行业方案" @ok="saveScheme">
            <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
				<a-form-item label="方案名称">
                    <a-input v-model:value="schemeState.form.father_name"></a-input>
                </a-form-item>
                <a-form-item label="简单描述">
                    <a-input v-model:value="schemeState.form.description"></a-input>
                </a-form-item>
                <a-form-item label="标签说明">
                    <a-input v-model:value="schemeState.form.remark"></a-input>
                </a-form-item>
			</a-form>
        </a-modal>

        <a-modal title="分支管理" v-model:visible="branchState.show"
            width="1200px"
            :footer="null"
            @cancel="branchState.show = false"
        >
            <a-table class="tb-overflow" row-key="server_id" :pagination="false" :data-source="branchState.list"
                :columns="[
                    { title: 'ID', dataIndex: 'server_id' },
                    { title: '分支名称', dataIndex: 'server_name' },
                    { title: '价格(元)', dataIndex: 'price', slots: { customRender: 'price' } },
                    { title: '天数', dataIndex: 'expire' },
                    { title: '应用数量(个)', dataIndex: 'program_count' },
                    { title: '状态', dataIndex: 'is_put_away', slots: { customRender: 'is_put_away' } },
                    { title: '排序', dataIndex: 'sort' },
                    { title: '创建时间', dataIndex: 'create_time' },
                    { title: '操作', dataIndex: 'action', slots: { customRender: 'action' } }
                ]" :scroll="{ y: 400 }"
            >
                <template #price="{record}">
                    <div><span class="f12">原价：</span>{{record.price}}</div>
                    <div><span class="f12">折扣：</span>{{record.discount_price}}</div>
                </template>
                <template #is_put_away="{record}">
                    <a-tag color="#00CC66" v-if="record.is_put_away == 1">上架 </a-tag>
                    <a-tag color="#FF6633" v-if="record.is_put_away == 0">已下架 </a-tag>
                </template>
                <template #action="{record}">
                    <a-button v-has="{ action: 'sass_server_add' }"
                        type="link"
                        size="small"
                        @click="showEditBranch(record)" >编辑
                    </a-button>
                    <!-- <a-button v-has="{ action: 'sass_server_del' }"
                        type="link"
                        size="small"
                        @click="deleteBranch(record.server_id)" >删除
                    </a-button> -->
                </template>
            </a-table>
        </a-modal>

        <a-modal v-model:visible="branchState.editShow" title="添加/编辑分支" @ok="saveBranch" width="1000px">
            <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
				<a-tabs defaultActiveKey>
					<a-tab-pane tab="基本信息" key="first">
                        <a-row>
                            <a-col :span="12">
                                <a-form-item label="行业方案">
                                    <a-select v-model:value="branchState.form.father_id" >
                                        <a-select-option :value="0">请选择行业方案</a-select-option>
                                        <a-select-option v-for="(item,index) in scheme" :key="index" :value="item.id">
                                            {{item.father_name}}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>
                                <a-form-item label="价格">
                                    <a-input v-model:value="branchState.form.price"></a-input>
                                </a-form-item>
                                <a-form-item label="折扣价格">
                                    <a-input v-model:value="branchState.form.discount_price"></a-input>
                                </a-form-item>
                                <a-form-item label="续费价格">
                                    <a-input v-model:value="branchState.form.renewal_price"></a-input>
                                </a-form-item>
                                <a-form-item label="续费折扣价">
                                    <a-input v-model:value="branchState.form.renewal_discount_price"></a-input>
                                    <div class="f12 lh16">注意: 续费折扣价为 给用户设置了享有续费折扣才能享用此续费折扣</div>
                                </a-form-item>
                            </a-col>
                            <a-col :span="12">
                                <a-form-item label="分支名称">
                                    <a-input v-model:value="branchState.form.server_name"></a-input>
                                </a-form-item>
                                <a-form-item label="到期天数">
                                    <a-input v-model:value="branchState.form.expire" type="number"></a-input>
                                </a-form-item>
                                <a-form-item label="描述">
                                    <a-input type="textarea" :rows="4" v-model:value="branchState.form.description"></a-input>
                                </a-form-item>
                                <a-form-item label="排序">
                                    <a-input v-model:value="branchState.form.sort"></a-input>
                                </a-form-item>
                                <a-form-item label="是否上架">
                                    <a-radio-group v-model:value="branchState.form.is_put_away">
                                        <a-radio :value="1">是</a-radio>
                                        <a-radio :value="0">否</a-radio>
                                    </a-radio-group>
                                </a-form-item>
                            </a-col>
                        </a-row>
					</a-tab-pane>
					<a-tab-pane key="second" tab="权限配置" v-if="$store.state.modulesPerm.sass==1">
						<a-form-item label="选择插件">
							<a-checkbox-group v-model:value="branchState.form.rule.plugins">
                                <div class="bl-plugins">
                                    <div class="bl-plugins-item" v-for="(item, i) in branchState.plugins" :key="i">
                                        <a-checkbox :value="item.key"> {{ item.value }} </a-checkbox>
                                    </div>
                                </div>
							</a-checkbox-group>
						</a-form-item>
						<a-form-item>
							<template #label>
								<span>商品条目限制</span>
								<a-tooltip title="条目限制等于-1表示不限制"><i class="ri-information-fill"></i></a-tooltip>
							</template>
							<a-input v-model:value="branchState.form.rule.goods.max_number" type="number"></a-input>
						</a-form-item>
						<a-form-item>
							<template #label>
								<span>土地条目限制</span>
								<a-tooltip title="条目限制等于-1表示不限制"><i class="ri-information-fill"></i></a-tooltip>
							</template>
							<a-input v-model:value="branchState.form.rule.land.max_number" type="number"></a-input>
						</a-form-item>
						<a-form-item>
							<template #label>
								<span>认养条目限制</span>
								<a-tooltip title="条目限制等于-1表示不限制"><i class="ri-information-fill"></i></a-tooltip>
							</template>
							<a-input v-model:value="branchState.form.rule.adoption.max_number" type="number"></a-input>
						</a-form-item>
						<a-form-item>
							<template #label>
								<span>存储空间大小</span>
								<a-tooltip title="单位:b"><i class="ri-information-fill"></i></a-tooltip>
							</template>
							<a-input v-model:value="branchState.form.rule.storage.size" type="number"></a-input>
						</a-form-item>
					</a-tab-pane>
				    <a-tab-pane key="third" tab="默认数据">
                        <a-form-item label="首页数据">
                            <a-select mode="multiple" v-model:value="branchState.form.default.index" placeholder="请选择首页默认数据">
                                <a-select-option
                                    v-for="( item,index) in branchState.defaultData.index"
                                    :key="index"
                                    :value="item.id">
                                    {{ item.name }}
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                        <a-form-item label="商品数据">
                            <a-select mode="multiple" v-model:value="branchState.form.default.goods" placeholder="请选择商品默认数据">
                                <a-select-option
                                    v-for="( item,index) in branchState.defaultData.goods"
                                    :key="index"
                                    :value="item.id">
                                    {{ item.name }}
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                        <a-form-item label="土地数据">
                            <a-select mode="multiple" v-model:value="branchState.form.default.land" placeholder="请选择土地默认数据">
                                <a-select-option
                                    v-for="( item,index) in branchState.defaultData.land"
                                    :key="index"
                                    :value="item.id">
                                    {{ item.name }}
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                        <a-form-item label="认养数据">
                            <a-select mode="multiple" v-model:value="branchState.form.default.adopt" placeholder="请选择认养默认数据">
                                <a-select-option
                                    v-for="( item,index) in branchState.defaultData.adopt"
                                    :key="index"
                                    :value="item.id">
                                    {{ item.name }}
                                </a-select-option>
                            </a-select>
                        </a-form-item>

                    </a-tab-pane>
                </a-tabs>
			</a-form>
        </a-modal>

    </div>
</template>
<script>
import { reactive, toRaw, toRefs } from 'vue'
import serviceModel from '@/api/saas/service.js'
import saasSetModel from "@/api/saas/set";
export default {
    setup(){
        let _d = reactive({
            scheme:[],
        })
        getScheme(1,999)
        function getScheme(page,limit){
            serviceModel.getServerScheme(page,limit,res=>_d.scheme = res.list)
        }
        let schemeState = useEditScheme({getScheme})
        let branchState = useBranch()

        return{
            ...toRefs(_d),
            getScheme,
            ...schemeState,
            ...branchState
        }
    }
}

//方案新增，编辑
function useEditScheme(obj){
    let schemeState = reactive({
        show:false,
        form:null
    })

    function editSchemeShow(row){
        schemeState.form = {
            id:row ? row.id :0,
            remark:row ? row.remark:"",
            father_name:row ? row.father_name:"",
            description:row ? row.description:""
        }
        schemeState.show = true
    }

    function saveScheme(){
        serviceModel.addOrEditServerScheme( toRaw(schemeState.form),()=>{
            schemeState.show = false
            obj.getScheme(1,999)
        })
    }
    const deleteSchemen = (id)=>serviceModel.deleteSchemen(id,()=>obj.getScheme(1,999))
    return{ schemeState ,editSchemeShow,saveScheme ,deleteSchemen }
}

//分支操作
function useBranch(){
    let branchState = reactive({
        list:[],
        form:null,
        show:false,
        editShow:false,
        plugins:[],
        defaultData:[],
    })

    // @param id 行业方案id
    function showBranch(id){
        branchState.branch = []
        serviceModel.getBranch(id,1,res=>branchState.list = res)
        branchState.show = true
    }

    function showEditBranch(row){
        if( row ){
            serviceModel.getBranchDetail(row.server_id,1,res=>{

                branchState.form = {
                    father_id:res ? res.father_id:0,
                    server_id:res ? res.server_id:0,
                    sort: res ? res.sort:99,
                    price: res ? res.price : "",
                    expire: res ? res.expire :"",
                    is_put_away: res ? res.is_put_away :0,
                    server_name: res ? res.server_name :"",
                    description: res ? res.description :"",
                    discount_price: res ? res.discount_price :"",
                    renewal_price: res ? res.renewal_price :"",
                    renewal_discount_price: res ? res.renewal_discount_price :"",
                    rule:res.rule ? {
                        goods:res ? res.rule.goods :{max_number: -1},
                        land:res ? res.rule.land :{max_number: -1},
                        adoption:res ? res.rule.adoption :{max_number: -1},
                        storage:res ? res.rule.storage :{size: 1024000000},
                        plugins:res ? res.rule.plugins :[],
                    }:{
                        goods:{max_number: -1},
                        land:{max_number: -1},
                        adoption:{max_number: -1},
                        storage:{size: 1024000000},
                        plugins:[],
                    },
                    default:res.default ? {
                        index:res.default.index,
                        goods:res.default.goods,
                        adopt:res.default.adopt,
                        land:res.default.land
                    }:{
                        index:[],
                        goods:[],
                        adopt:[],
                        land:[]
                    }
                }
                branchState.editShow = true
                getDefaultData(res.server_id)
            })
        }else{
            branchState.form = {
                father_id:0,
                server_id:0,
                sort: 99,
                price: "",
                expire: "",
                is_put_away:0,
                server_name:"",
                description:"",
                discount_price:"",
                renewal_price:"",
                renewal_discount_price:"",
                rule:{
                    goods:{max_number: -1},
                    land:{max_number: -1},
                    adoption:{max_number: -1},
                    storage:{size: 1024000000},
                    plugins:[],
                },
                default:{
                    index:[],
                    goods:[],
                    adopt:[],
                    land:[]
                }
            }
            branchState.editShow = true
            getDefaultData()
        }

        //权限信息
        if( branchState.plugins.length == 0 ){
            serviceModel.getSaasPlugin(res=>{
                let temp = Object.keys(res);
                temp.forEach(item => {
                    branchState.plugins.push({ key: item, value: res[item] });
                });
            })
        }
    }

    /**
     * 获取可选的默认数据
     * @param server_id 分支的id
     */
    function getDefaultData(server_id=0){
        let param = { scene:'all'}
        if( server_id ) param.server_id = server_id
        saasSetModel.getDefaultData(1,999, param,res=>{
            branchState.defaultData = res
        })
    }

    function saveBranch(){
        serviceModel.addOrEditBranch(JSON.parse(JSON.stringify(branchState.form)),()=>{
            branchState.editShow = false
            showBranch(branchState.form.father_id)
        })
    }

    return{ branchState ,showBranch,showEditBranch,saveBranch }
}

</script>


<style scoped lang="scss">
.bl-plugins{
    display: flex;
    flex-wrap: wrap;

    &-item{
        width: 140px;
    }
}

</style>
